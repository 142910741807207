import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import background from "../../assets/images/landing_background.png";

export const LandingMainGrid = withStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `url(${background})`,
    paddingLeft: "20px",
    paddingRight: "20px",
    minHeight: "100vh",
    overflow: "hidden",
  },
}))(Grid);

export const LandingIntroductionGrid = withStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    padding: "50px",
    "& a": {
      display: "block",
      maxWidth: "370px",
    },
    "& img": {
      width: "100%",
      objectFit: "contain",
    },
  },
}))(Grid);

export const LandingSubscriptionGrid = withStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    paddingLeft: "20px",
    paddingRight: "20px",
    position: "relative",
    backdropFilter: "blur(104.615px)",
    minHeight: "100vh",
    background:
      "radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(165, 239, 255, 0.03) 0%, rgba(110, 191, 244, 0.00671875) 77.08%, rgba(70, 144, 213, 0) 100%)",
  },
}))(Grid);
