import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./i18n";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Landing from "./components/Landing/Landing";

class App extends Component {
  /* async componentDidMount() {

        if (getNCToken() === null) {
           await setNCToken();
        }
        await verifyToken();
    }*/

  render() {
    /*  if(!this.state.loginned){
            return <Login/>;
        }*/
    return (
      <Router>
        {/*<div className="row">*/}
        {/*<Header/>*/}
        {/*<ToastContainer/>*/}
        <Switch>
          <Route exact path="/" component={Landing} />
          {/*<Route exact path='/' component={ByCategory} />*/}
          {/*<Route path='/professions/:id' component={List} />*/}
          {/*<Route path='/departments' component={DepartmentsList} />*/}
          {/*<Route path='/department/:id' component={DepartmentPage}/>*/}
          {/*<Route path='/departmentWithWork/:id/:workplace' component={DepartmentWithWorkplace}/>*/}
          {/*<Route path='/profession/:id' component={ByProfession}/>*/}
          {/*<Route path='/doctor/:id' component={OneDoctor} />*/}
          {/*<Route path="/results" component={CallBySearch} />*/}
          {/*<Route path="/create/call/:type/:id" component={CreateCall} />*/}
          {/*<Route path="/order/:drugId" component={Order} />*/}
          {/*<Route path="/labarator/prices/:labId" component={Pricelist} />*/}
          {/*<Route path="/labarator/call/:labId" component={LabCallForm} />*/}
          {/*<Route path="/labarator/compare" component={CompareService} />*/}
          {/*<Route path="/labarator/services/:type" component={LabServices} />*/}
          {/*<PublicRoute path="/login" component={Login} />*/}
          {/*<DoctorRoute path="/profile" component={Profile}/>*/}
          {/*<DoctorRoute path={`/profile/info`} component={Info}/>*/}
          {/*<DoctorRoute path={`/doctor/graphic`} component={Graphic}/>*/}
          {/*<DoctorRoute path="/profile/create/card/:call" component={CreateCard}/>*/}
          {/*<DoctorRoute path="/profile/edit/card/:card" component={EditCard}/>*/}
          {/*<PatientRoute path="/patient" component={Patient}/>*/}
          {/*<LabRoute path={'/lab'} component={LabProfile}/>*/}
          {/*<Route path="/labsByType/:type" component={LabByType} />*/}
          {/*<Route path="/labs" component={LabList} />*/}
          {/*<Route path="/pharms" component={PharmList}/>*/}
          {/*<Route path="/drugs" component={DrugList}/>*/}
          {/*<Route path="/type/:id" component={ByType}/>*/}
          {/*<Route path="*">*/}
          {/*    <NoMatch />*/}
          {/*</Route>*/}
          <Redirect from="*" to="/" />
        </Switch>

        {/*<Menu/>*/}
        {/*</div>*/}
      </Router>
    );
  }
}

export default App;
