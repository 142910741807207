import React from "react";
import {
  LandingIntroductionGrid,
  LandingMainGrid,
  LandingSubscriptionGrid,
} from "./Landing.styles";
import logo from "../../assets/images/logo.png";
import SubscriptionForm from "../SubscriptionForm/SubscriptionForm";
import { Grid, useMediaQuery } from "@mui/material";
import details from "../../assets/images/landing_details.svg";

const Landing = () => {
  const matches = useMediaQuery("(max-width: 900px)");

  return (
    <LandingMainGrid
      container
      spacing={0}
      padding={0}
      sx={{ height: matches ? "fit-content" : "100vh" }}
    >
      <LandingIntroductionGrid
        item
        md={7}
        xs={12}
        sx={{ display: matches ? "none" : "flex" }}
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <a href="/">
          <img src={logo} alt="ItClinic" />
        </a>
        <img src={details} alt="details" />
      </LandingIntroductionGrid>
      <LandingSubscriptionGrid item md={5} xs={12}>
        <Grid
          item
          height={matches ? "fit-content" : "100%"}
          sx={{ paddingTop: matches ? "20px" : 0 }}
          container
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <SubscriptionForm />
        </Grid>
        {/*TODO: do not delete this section. It is supposed to be used in future*/}
        {/*<Social*/}
        {/*  container*/}
        {/*  flexWrap={"nowrap"}*/}
        {/*  alignItems={"center"}*/}
        {/*  sx={{*/}
        {/*    position: matches ? "static" : "absolute",*/}
        {/*    paddingTop: matches ? "20px" : 0,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <ul>*/}
        {/*    <li>*/}
        {/*      <a href="/" target={"_blank"}>*/}
        {/*        <img src={linkedin} alt="linkedin" />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <a href="/" target={"_blank"}>*/}
        {/*        <img src={facebook} alt="facebook" />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <a href="/" target={"_blank"}>*/}
        {/*        <img src={instagram} alt="instagram" />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <a href="mailto:" target={"_blank"}>*/}
        {/*        <img src={envelope} alt="envelope" />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</Social>*/}
      </LandingSubscriptionGrid>
    </LandingMainGrid>
  );
};

export default Landing;
