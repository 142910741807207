import {
  TextField,
  Button,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"]);

console.log("isSafari", isSafari);

export const Field = withStyles(() => ({
  root: {
    width: "100%",
    height: "48px",
    marginBottom: "24px !important",
    "& .MuiInputBase-root": {
      backgroundColor: "transparent",
      width: "100%",
      height: "100%",
      borderStyle: "solid",
      borderImage: "linear-gradient(130deg, #98F9FF, #EABFFF) 10",
      // ["-webkitMask"]:
      //   "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      // ["-webkitMaskComposite"]: "xor",
      maskComposite: "exclude",
      inset: 0,
      borderWidth: "3px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "unset",
        },
      },
      "& input": {
        border: "unset",
        width: "100%",
        height: "100%",
        fontSize: "14px",
        padding: "16px",
        fontWeight: 400,
        borderRadius: "8px",
        color: "#ffffff",
        boxSizing: "border-box",
        "&:-internal-autofill-selected": {
          backgroundColor: "transparent !important",
          color: "#ffffff",
        },
        "&::placeholder": {
          opacity: 1,
        },
      },
    },
  },
}))(TextField);

export const PhoneCode = withStyles(() => ({
  root: {
    "&.MuiTypography-root": {
      width: "18%",
      minWidth: "fit-content",
      height: "48px",
      marginBottom: "24px !important",
      borderStyle: "solid",
      borderImage: "linear-gradient(130deg, #98F9FF, #EABFFF) 10",
      borderWidth: "3px",
      textAlign: "center",
      fontSize: "14px",
      padding: "16px",
      fontWeight: 400,
      color: "#ffffff",
      lineHeight: 1,
      cursor: "auto",
    },
  },
}))(Typography);

export const SubscriptionButton = withStyles(() => ({
  root: {
    width: "100%",
    height: "72px",
    "&.MuiButtonBase-root": {
      borderStyle: "solid",
      borderImage: "linear-gradient(130deg, #98F9FF, #EABFFF) 10",
      // ["-webkitMask"]:
      //   "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      // ["-webkitMaskComposite"]: "xor",
      maskComposite: "exclude",
      inset: 0,
      borderWidth: "3px",
      fontWeight: 700,
      color: "#ffffff",
      backgroundColor: "#00B4FF",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "#00B4FF",
      },
    },
  },
}))(Button);

export const Social = withStyles(() => ({
  root: {
    position: "absolute",
    bottom: 0,
    "& ul": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      "& li": {
        listStyleType: "none",
        marginLeft: "5px",
        marginRight: "5px",
      },
    },
  },
}))(Grid);

export const SubscriptionSelect = withStyles(() => ({
  root: {
    width: "30%",
    marginBottom: "24px !important",
    "& .MuiOutlinedInput-root": {
      height: "48px",
      borderStyle: "solid",
      borderImage: "linear-gradient(130deg, #98F9FF, #EABFFF) 10",
      // ["-webkitMask"]:
      //   "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      // ["-webkitMaskComposite"]: "xor",
      maskComposite: "exclude",
      inset: 0,
      borderWidth: "3px",
      color: "#ffffff",
      fontWeight: 400,
      width: "fit-content",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
    },
    "& .MuiInputLabel-root": {
      fontWeight: 400,
      color: "#ffffff",
      transform: "translate(10px, 10px)",
    },
    "& .MuiSelect-select": {
      padding: "5px",
      "&.Mui-disabled": {
        color: "#ffffff",
        ["-webkitTextFillColor"]: "#ffffff",
        padding: "10px !important",
        fontSize: "14px",
      },
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },
  },
}))(FormControl);

export const useSubscriptionFormStyles = makeStyles({
  textarea: {
    width: "100%",
    height: "120px !important",
    marginBottom: "24px !important",
    resize: "none",
    padding: "16px",
    paddingTop: "13px",
    lineHeight: 1,
    fontSize: "14px",
    borderStyle: "solid",
    borderImage: "linear-gradient(130deg, #98F9FF, #EABFFF) 10",
    // ["-webkitMask"]:
    //   "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    // ["-webkitMaskComposite"]: "xor",
    maskComposite: "exclude",
    inset: 0,
    borderWidth: "3px",
    borderRadius: "5px",
    fontWeight: 400,
    boxSizing: "border-box",
    color: "#ffffff",
    backgroundColor: "transparent",
    overflow: "auto !important",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: "#ffffff",
    },
  },
  mainHeader: {
    fontSize: "24px !important",
    color: "#ffffff",
    marginBottom: "48px !important",
    fontWeight: "700 !important",
    textAlign: "center",
  },
});
