import React from "react";
import { Grid, TextareaAutosize, Typography } from "@mui/material";
import {
  Field,
  PhoneCode,
  SubscriptionButton,
  useSubscriptionFormStyles,
} from "./SubscriptionForm.styles";
import useSubscriptionForm from "./SubscriptionForm.hook";

const SubscriptionForm = () => {
  const {
    formData: { name, comment, category, phone, email },
    handleChange,
    handleSubmit,
    successMessage,
  } = useSubscriptionForm();
  const { textarea, mainHeader } = useSubscriptionFormStyles();

  return (
    <>
      <Typography variant={"h1"} className={mainHeader}>
        Համագործակցման հայտ
      </Typography>
      <form onSubmit={handleSubmit}>
        <Field
          defaultValue={name}
          name={"name"}
          id={"name"}
          required
          placeholder={"Անուն Ազգանուն / Ընկերության անվանում*"}
          onChange={(e) => {
            const { name, value } = e.target;
            handleChange(name, value);
          }}
        />
        <Field
          defaultValue={category}
          name={"category"}
          required
          id={"category"}
          placeholder={"Գործունեության տեսակ*"}
          onChange={(e) => {
            const { name, value } = e.target;
            handleChange(name, value);
          }}
        />
        <Grid
          container
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={0}
          flexWrap={"nowrap"}
        >
          <PhoneCode variant={"body2"}>+374</PhoneCode>
          <Field
            defaultValue={phone}
            name={"phone"}
            type={"number"}
            sx={{ width: "80%" }}
            required
            id={"phone"}
            placeholder={"Հեռախոսահամար"}
            onChange={(e) => {
              const { name, value } = e.target;
              handleChange(name, value);
            }}
          />
        </Grid>
        <Field
          defaultValue={email}
          name={"email"}
          required
          type={"email"}
          id={"email"}
          placeholder={"Էլ հասցե*"}
          onChange={(e) => {
            const { name, value } = e.target;
            handleChange(name, value);
          }}
        />
        <TextareaAutosize
          value={comment}
          name={"comment"}
          id={"comment"}
          onChange={(e) => {
            const { name, value } = e.target;
            handleChange(name, value);
          }}
          className={textarea}
          placeholder={"Թողնել հաղորդագրություն"}
          maxRows={4}
        />
        {successMessage && (
          <Typography
            variant={"body2"}
            sx={{
              color: "#ffffff",
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            Դիմումը հաջողությամբ ընդունվել է
          </Typography>
        )}
        <SubscriptionButton type={"submit"}>Համագործակցել</SubscriptionButton>
      </form>
    </>
  );
};

export default SubscriptionForm;
