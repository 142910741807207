import { useEffect, useState } from "react";
import axios from "axios";

const useSubscriptionForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    email: "",
    phone: "",
    comment: "",
  });
  const [successMessage, setSuccessMessage] = useState(false);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("formData", formData);
    axios.post("/addCollaborator", formData).then((response) => {
      const { status } = response;
      setSuccessMessage(status === 200 ? true : false);
      status === 200 &&
        setFormData({
          name: "",
          category: "",
          email: "",
          phone: "",
          comment: "",
        });
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => setSuccessMessage(false), 4000);
    return () => clearTimeout(timer);
  }, [successMessage]);

  return { formData, handleChange, handleSubmit, successMessage };
};

export default useSubscriptionForm;
